require('./src/global.css')

const addScript = url => {
  const script = document.createElement('script')
  script.src = url
  document.getElementsByTagName(`head`)[0].appendChild(script)
}

// eslint-disable-next-line no-unused-vars
exports.onRouteUpdate = s => {
  addScript(`//scripts.iconnode.com/${process.env.GATSBY_WHATCONVERTS_NUMBER}.js`)
}
